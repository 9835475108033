import { LOG_IN, LOG_OUT } from '../actionTypes/userManagement';

export const logIn = (accessToken) => dispatch => {
    dispatch({
        type: LOG_IN,
        payload: accessToken
    })
};

export const logOut = () => dispatch => {
    dispatch({
        type: LOG_OUT
    })
};