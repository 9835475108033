import { SET_INITIAL_ARTICLES_SUCCESS, SET_INITIAL_ARTICLES_ERROR, SELECT_ARTICLE } from '../actionTypes/articles';


export const getInitialArticles = (isSuccess, payload) => dispatch => {
    if (isSuccess) {
        dispatch({
            type: SET_INITIAL_ARTICLES_SUCCESS,
            payload
        })
    } else {
        dispatch({
            type: SET_INITIAL_ARTICLES_ERROR,
            payload
        })
    }
};

export const selectArticle = (payload) => dispatch => {
    dispatch({
        type: SELECT_ARTICLE,
        payload
    })
};