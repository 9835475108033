import { SET_INITIAL_ARTICLES_SUCCESS, SET_INITIAL_ARTICLES_ERROR, SELECT_ARTICLE } from '../actionTypes/articles';

export default function HomepageReducer(state = [], action = []) {
    switch (action.type) {
        case SET_INITIAL_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: action.payload
            }
        case SET_INITIAL_ARTICLES_ERROR:
            return {
                ...state,
                articles: "Error"
            }
        case SELECT_ARTICLE:
            return {
                ...state,
                selectedSubject: action.payload
            }
        default:
            return state;
    }
}