import './Subject.css';

import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Subject = () => {
    const { lessons, selectedSubject } = useSelector(state => state.HomePage);

    return (
        lessons ? lessons?.filter(lesson => lesson.title.toLowerCase() === selectedSubject)?.map(lesson => {
            return (<>
                <div key={lesson.id} className="subject">
                    <header className="subject__header">
                        <h1>{lesson.title}</h1>
                        <p>
                            Pick and master one or more programming languages from begginner to advanced.
                        </p>
                    </header>
                    <div className="subject__types">
                        {lesson?.types?.map(type =>
                            <div className="subject__types__wrapper" key={type.name}>
                                <div className="subject__typesName">
                                    <Link to={`/subjects/${lesson.title.toLowerCase()}/${type.name.toLowerCase() === 'c#' ? 'c-sharp' : type.name.toLowerCase()}`} >

                                        <h3>
                                            {type.name === 'c-sharp' ? 'C#' : type.name}
                                        </h3>
                                    </Link>

                                </div>
                                <div className="subject__typesDescription">
                                    <p>
                                        {type.description}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>)
        }) : <h1>Loading...</h1>
    )
}

export default Subject;
