import './index.css';

import React from 'react'

import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch } from "react-redux";
import { logOut } from '../../actions/userManagement';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
    const dispatch = useDispatch();

    const validateFields = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!values.password) {
            errors.password = 'Password is required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    return (
        <div className="profile">
            <div className='profile__heading'>Welcome to your profiles settings</div>
            <div className="profile__container">
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validate={values => validateFields(values)}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <>
                            <Form>
                                <h5>Email</h5>
                                <Field disabled={true} type="email" name="email" />
                                <ErrorMessage name="email" component="div" />
                                <h5>Username</h5>
                                <Field disabled={true} type="email" name="email" />
                                <ErrorMessage name="email" component="div" />
                                <h5>Want to change your password?</h5>
                                <h5>Enter current password</h5>
                                <Field type="password" name="password" />
                                <ErrorMessage name="password" component="div" />
                                <h5>Repeat password</h5>
                                <Field type="password" name="repeatPassword" />
                                <ErrorMessage name="repeatPassword" component="div" />
                                <button type="submit" disabled={true}>
                                    Change passwrod
                                </button>
                            </Form>
                            <Form>
                                <Link style={{ textDecoration: 'none' }} onClick={() => dispatch(logOut())} to='/' >
                                    <button disabled={isSubmitting}  >
                                        Logout
                                    </button>
                                </Link>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Profile
