import './Footer.css';

import React from 'react';
import Logo from '../../../static/Logo.png';
import { Link } from 'react-router-dom';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import PatreonLogo from '../../../static/Patreon.png';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const Footer = () => {
    return (
        <div className="footer">
            <Link to="/">
                <img className="footer__logo" src={Logo} alt="" />
            </Link>
            <div className="footer__left">
                <h4> </h4>
                <h4>
                    <EmailIcon /> learn4progress@yahoo.com
                </h4>
                <h4> </h4>
            </div>
            <div className="footer__center">
                <h4>
                    <a className="nav__link" style={{ textDecoration: 'none' }} href="https://www.youtube.com/channel/UCZfeQyDEXtV1GN1wp1dyqgw">
                        <YouTubeIcon /> YouTube
                    </a>
                </h4>
                <h4>
                    <a className="nav__link" style={{ textDecoration: 'none' }} href="https://www.facebook.com/Learn4Progress">
                        <FacebookIcon /> Facebook
                    </a>
                </h4>
                <h4>
                    <a className="nav__link" style={{ textDecoration: 'none' }} href="https://www.patreon.com/learn4progress">
                        <img className="footer__patreonLogo" src={PatreonLogo} alt="" /> Patreon
                    </a>
                </h4>
            </div>
            <div className="footer__right">
                <h4>
                    <a className="nav__link" style={{ textDecoration: 'none' }} href="https://www.instagram.com/learn4progress/">
                        <InstagramIcon /> Instagram
                    </a>
                </h4>
                <h4>
                    <a className="nav__link" style={{ textDecoration: 'none' }} href="https://twitter.com/Learn4Progress">
                        <TwitterIcon /> Twitter
                    </a>
                </h4>
                <h4>
                    <Link className="nav__link" style={{ textDecoration: 'none' }} to="/donation">
                        <MonetizationOnIcon /> Donate
                    </Link>
                </h4>
            </div>
        </div>
    )
}

export default Footer;
