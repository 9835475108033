import './Donation.css';

import React from 'react';
import PatreonLogo from '../../static/Patreon.png';
import QRDontion from '../../static/QRCode.png';

const Donation = () => {
    return (
        <div className="donation">
            <h1>If you would like to support me developing the platform please donate</h1>
            <h2>Your donation will be highly appreciated</h2>
            <form className='donation__paypal' action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="772BFC4EQG9XY" />
                <input className="donation__paypalButton" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            </form>
            <img src={QRDontion} alt="" />
            <h3>or</h3>
            <h4 className="donation__patreon">
                <a className="nav__link" style={{ textDecoration: 'none' }} href="https://www.patreon.com/learn4progress">
                    <img className="footer__patreonLogo" src={PatreonLogo} alt="" /> Patreon
                </a>
            </h4>
        </div>
    )
}

export default Donation;
