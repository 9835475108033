import './Login.css';

import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { logIn } from '../../actions/userManagement';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { API_HOST } from '../../constants';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const dispatch = useDispatch();
    const { isLoggedIn } =
        useSelector((state) => state.UserManagementReducer) || false;

    const getData = async (data) => {
        await axios.post(`${API_HOST}/login/user`, data)
            .then(res => {
                const { msg, isLoggedIn, accessToken } = res.data;
                toast.info(msg)

                if (isLoggedIn) {
                    dispatch(logIn(accessToken))
                } else {
                    alert('Error: ', isLoggedIn)
                }
            })
            .catch(err => {
                const { msg } = err;
                toast.info(msg)
            });
    }

    const validateFields = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!values.password) {
            errors.password = 'Password is required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    return isLoggedIn ? <Redirect to="/" /> : (
        <div className="login">
            <div className="login__container">
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validate={values => validateFields(values)}
                    onSubmit={async (values, { setSubmitting }) => {
                        await getData(values)
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <>
                            <ToastContainer />
                            <Form>
                                <h5>Email</h5>
                                <Field type="email" name="email" />
                                <ErrorMessage name="email" component="div" />
                                <h5>Password</h5>
                                <Field type="password" name="password" />
                                <ErrorMessage name="password" component="div" />
                                <button type="submit" disabled={isSubmitting}>
                                    Sign In
                                </button>
                            </Form>
                            <h3>Don't have an account?</h3>
                            <Form>
                                <Link style={{ textDecoration: 'none' }} to='/register' >
                                    <button disabled={isSubmitting}  >
                                        Sign Up
                                    </button>
                                </Link>
                            </Form>
                            <p>
                                By signing-in you agree to Learn4Progress conditions of use.
                                Please see our Privacy Notice, our Cookies Notice and our interest-Based Ads Notice.
                            </p>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Login;
