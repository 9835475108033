import "./Body.css";

import React from "react";
import { LessonCard } from "../../index";
import { useSelector } from "react-redux";
import YouTube from "react-youtube";

const Body = () => {
  const lessons = useSelector((state) => state.HomePage.lessons) || [];
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <div>
      <div className="body__title">
        <h1>Videos and lessons for self improvement</h1>
      </div>
      <div className="body__lessons">
        {lessons?.map((lesson) => {
          return (
            <LessonCard
              key={lesson.title}
              imgURL={lesson.imgURL}
              alt={lesson.alt}
              title={lesson.title}
              types={lesson.types}
            />
          );
        })}
      </div>
      <div className="body__videos">
        <div>
          <YouTube
            className="topic__video"
            videoId="zOjov-2OZ0E"
            opts={opts}
            onReady={_onReady}
          />
        </div>
        <div>
          <YouTube
            className="topic__video"
            videoId="hE2NsJGpEq4"
            opts={opts}
            onReady={_onReady}
          />
        </div>
        <div>
          <YouTube
            className="topic__video"
            videoId="0aNNYEUARAk"
            opts={opts}
            onReady={_onReady}
          />
        </div>
      </div>
    </div>
  );
};

export default Body;
