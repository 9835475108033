import "./LessonCard.css";

import React from "react";
import { Link } from "react-router-dom";
import { selectSubject } from "../../../actions/homepage";
import { useDispatch } from "react-redux";

const LessonCard = ({ imgURL, alt, title, types }) => {
  const dispatch = useDispatch();

  return (
    <div className="lessonCard">
      <Link
        style={{ textDecoration: "none" }}
        to={`/subjects/${title.toLowerCase()}`}
        className="lessonCard__header"
        onClick={() => dispatch(selectSubject(title.toLowerCase()))}
      >
        <img src={imgURL} alt={alt} />
        <h4>{title}</h4>
      </Link>
      <div className="lessonCard__grades">
        {types?.map((type) => (
          <Link
            style={{ textDecoration: "none" }}
            key={type.name}
            to={`/subjects/${title.toLowerCase()}/${type.name.toLowerCase()}`}
          >
            <div key={type.name} className="lessonCard__gradeLink">
              {type.name === "c-sharp" ? "C#" : type.name}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LessonCard;
