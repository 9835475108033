import './Articles.css';

import React, { useEffect } from 'react'
import { getInitialArticles } from '../../actions/articles';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

const Articles = () => {
    const dispatch = useDispatch();
    const articles = useSelector((state) => state.Articles.articles) || [];

    useEffect(() => {
        const getData = async () => {
            await axios.get('/articles')
                .then(res => {

                    const data = res.data;
                    const isSuccess = true;
                    dispatch(getInitialArticles(isSuccess, data));
                });
        }

        getData();
    }, [dispatch])

    return (
        <div className="articles">
            {articles?.map(article => <div key={article._id}>
                <h1>{article.title}</h1>
                <img src={article.src} alt={article.alt}></img>
                <p>{article.text}</p>
            </div>
            )}
        </div>
    )
}

export default Articles;
