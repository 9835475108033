import { SET_INITIAL_LESSONS_SUCCESS, SET_INITIAL_LESSONS_ERROR, SELECT_SUBJECT } from "../actionTypes/homepage";

export default function HomepageReducer(state = [], action = []) {
    switch (action.type) {
        case SET_INITIAL_LESSONS_SUCCESS:
            return {
                ...state,
                lessons: action.payload
            }
        case SET_INITIAL_LESSONS_ERROR:
            return {
                ...state,
                lessons: "Error"
            }
        case SELECT_SUBJECT:
            return {
                ...state,
                selectedSubject: action.payload
            }
        default:
            return state;
    }
}