import "./Topic.css";

import React, { useState } from "react";
import YouTube from "react-youtube";
import { useSelector } from "react-redux";

const Topic = () => {
  const segment_str = window.location.pathname;
  const segment_array = segment_str.split("/");
  const last_segment = segment_array.pop();
  const { lessons } = useSelector((state) => state.HomePage);
  const topic = lessons
    ? lessons[0].types.filter(
        (type) => type.name.trim().toLowerCase() === last_segment.toLowerCase()
      )[0]
    : [];
  const [videoUrl, setVideoUrl] = useState(topic.levels[0].url);

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <div className="topic">
      <div className="topic__left">
        <YouTube
          className="topic__video"
          videoId={videoUrl}
          opts={opts}
          onReady={_onReady}
        />
      </div>
      <div className="topic__right">
        <h1>{topic?.title === "C-sharp" ? "C#" : topic?.title}</h1>
        <div className="topic__levels">
          {topic?.levels?.map((level) => {
            return (
              <div
                key={level?.id}
                className="topic_level"
                onClick={() => setVideoUrl(level?.url)}
              >
                {level?.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Topic;
