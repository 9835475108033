import { SET_INITIAL_LESSONS_ERROR, SET_INITIAL_LESSONS_SUCCESS, SELECT_SUBJECT } from '../actionTypes/homepage';

export const getInitialData = (isSuccess, payload) => dispatch => {
    if (isSuccess) {
        dispatch({
            type: SET_INITIAL_LESSONS_SUCCESS,
            payload
        })
    } else {
        dispatch({
            type: SET_INITIAL_LESSONS_ERROR,
            payload
        })
    }
};

export const selectSubject = (payload) => dispatch => {
    dispatch({
        type: SELECT_SUBJECT,
        payload
    })
};