import { LOG_IN, LOG_OUT } from "../actionTypes/userManagement";

export default function UserManagementReducer(state = { isLoggedIn: false }, action = []) {
    switch (action.type) {
        case LOG_IN:
            sessionStorage.setItem("accessToken", action.payload)

            return {
                ...state,
                isLoggedIn: true
            }
        case LOG_OUT:
            sessionStorage.removeItem('accessToken')

            return {
                ...state,
                isLoggedIn: false
            }
        default:
            return state;
    }
}