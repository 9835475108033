import './Progress.css';

import React from 'react';

const Progress = () => {
    return (
        <div className="progress">
            <h1>Under Development</h1>
            <h3>New deploy every Sunday</h3>
        </div>
    )
}

export default Progress;
