import './App.css';

import React, { useEffect, useCallback } from 'react'
import { Header, Body, Footer } from './components/index'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getInitialData } from './actions/homepage';
import axios from 'axios';
import { API_HOST } from './constants';
import * as Pages from './pages/index';

function App() {
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    await axios.get(`${API_HOST}/classes`)
      .then(res => {
        const data = res.data;
        const isSuccess = true;
        dispatch(getInitialData(isSuccess, data));
      });
  }, [dispatch])

  useEffect(() => {
    getData();
  })

  return (
    <div className="app">
      <Router>
        <Header />
        <div className="body_app">
          <Switch>
            <Route path="/profile">
              <Pages.Profile />
            </Route>
            <Route path="/login">
              <Pages.Login />
            </Route>
            <Route path="/register">
              <Pages.Register />
            </Route>
            <Route path="/articles">
              <Pages.Articles />
            </Route>
            <Route path="/subscribe">
              <Pages.Subscribe />
            </Route>
            <Route path="/progress">
              <Pages.Progress />
            </Route>
            <Route path="/subjects/:subjectName/:topic">
              <Pages.Topic />
            </Route>
            <Route path="/subjects/:subjectName">
              <Pages.Subject />
            </Route>
            <Route path="/donation">
              <Pages.Donation />
            </Route>
            <Route path="/login">
              <Pages.Login />
            </Route>
            <Route path="/">
              <Body />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
