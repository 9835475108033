import './Register.css';

import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    const [isRegisterSuccessful, setIsRegisterSuccessful] = useState(false)

    const registerUser = async (data, resetForm) => {
        await axios.post('/register', data)
            .then(res => {
                const { msg, isRegistered } = res.data;
                toast.success(msg);
                resetForm();
                setTimeout(() => {
                    setIsRegisterSuccessful(isRegistered)
                }, 2500);
            }).catch(err => {
                const { msg } = err;
                toast.info(msg)
            });
    }

    const validateFields = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!values.username) {
            errors.username = 'Username is required';
        } else if (!values.password) {
            errors.password = 'Password is required';
        } else if (!values.repeatPassword) {
            errors.repeatPassword = 'Reapeat password is required';
        } else if (values.password !== values.repeatPassword) {
            errors.repeatPassword = 'Passwords does not match';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    return isRegisterSuccessful ? <Redirect to="/login" /> : (
        <div className="register">
            <div className="register__container">
                <Formik
                    initialValues={{ email: '', username: '', password: '', repeatPassword: '' }}
                    validate={values => validateFields(values)}

                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        await registerUser(values, resetForm);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <>
                            <ToastContainer />
                            <Form>
                                <h5>Email</h5>
                                <Field type="email" name="email" placeholder=" email" />
                                <ErrorMessage name="email" component="div" />
                                <h5>Username</h5>
                                <Field type="text" name="username" placeholder=" username" />
                                <ErrorMessage name="username" component="div" />

                                <h5>Password</h5>
                                <Field type="password" name="password" placeholder=" password" />
                                <ErrorMessage name="password" component="div" />

                                <h5>Repeat password</h5>
                                <Field type="password" name="repeatPassword" placeholder=" repeat password" />
                                <ErrorMessage name="repeatPassword" component="div" />
                                <button type="submit" disabled={isSubmitting}>
                                    Sign Up
                                </button>
                            </Form>
                            <h3>Already have an account?</h3>
                            <Form>
                                <Link style={{ textDecoration: 'none' }} to="/login">
                                    <button disabled={isSubmitting}  >
                                        Sign In
                                    </button>
                                </Link>
                            </Form>
                            <p>
                                By signing-up you agree to Learn4Progress conditions of use.
                                Please see our Privacy Notice, our Cookies Notice and our interest-Based Ads Notice.
                            </p>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Register;
