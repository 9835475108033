import './Subscribe.css';

import React from 'react';

const Subscribe = () => {
    return (
        <div className="subscribe">
            <h1>Under Development</h1>
        </div>
    )
}

export default Subscribe;
