import "./Header.css";

import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../actions/userManagement";

const Header = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } =
    useSelector((state) => state.UserManagementReducer) || false;

  return (
    <>
      {isLoggedIn ? (
        <nav className="overlay">
          <Link to="/">
            {" "}
            <h1 className="overlay__header">Learn4Progress</h1>
          </Link>
          <ul>
            <li>
              <Link className="nav__link" to="/profile">
                Profile
              </Link>
              <> / </>
              <Link
                className="nav__link"
                onClick={() => dispatch(logOut())}
                to="/"
              >
                Logout
              </Link>
            </li>
            <li>
              <Link className="nav__link" to="/articles">
                Articles
              </Link>
            </li>
            <li>
              <Link className="nav__link" to="/donation">
                Donate
              </Link>
            </li>
          </ul>
        </nav>
      ) : (
        <nav className="overlay">
          <Link to="/">
            <h1 className="overlay__header">Learn4Progress</h1>
          </Link>
          <ul>
            <li>
              <Link className="nav__link" to="/login">
                Login
              </Link>
              <span> or </span>
              <Link className="nav__link" to="/register">
                Register
              </Link>
            </li>
            <li>
              <Link className="nav__link" to="/articles">
                Articles
              </Link>
            </li>
            <li>
              <Link className="nav__link" to="/donation">
                Donate
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Header;
